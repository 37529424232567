.admin-ordenes{
    background-color: #CAEAA1;
    min-height: 100%;
    position: absolute;
}

/*sweet alert*/
.title-class{
    font-size: 1.35em;
}
.popup-class{
    border-radius: 2rem;
}

.confirmbutton-class{
    background: #16a34a;
    color:#fff;
    cursor: pointer;
    font-size: 16px;
    margin: 0 5px 0 5px;
    border-radius: 9999px;
    overflow: hidden;
    padding: 5px 20px;
    position: relative;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-color: #48bb78;
}

.cancelbutton-class{
    background: #d33;
    color:#fff;
    cursor: pointer;
    font-size: 16px;
    margin: 0 5px 0 5px;
    border-radius: 9999px;
    overflow: hidden;
    padding: 5px 20px;
    position: relative;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-color: rgb(182, 76, 76);
}
/*usuarios*/
label {
    font-size: 15px!important;
    color: #48bb78!important;
}

.table-user {
    width: auto!important;
}
.custom-input {
    margin: 0 auto 0;
    position: relative;
    width: 250px;
    display: flex;
    align-items: center;
  }
.custom-input-file {
    color: #48bb78;
    cursor: pointer;
    font-size: 14px;
    margin: 0 auto 0;
    height: 150px;
    border-radius: 100px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    width: 150px;
    border-style: dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-color: #48bb78;
    border: dashed;
}

  
.custom-input-file .file-input {
   cursor: pointer;
   font-size: 10000px;
   margin: 0;
   opacity: 0;
   outline: 0 none;
   padding: 0;
   position: absolute;
   right: -1000px;
   top: -1000px;
}

input[type=file] {
    pointer-events: all;
}

.icon-img {
    position: absolute;
    top: 95px;
    left: 160px;
}

.detail-img {
    width: 40px;
    height: 40px;
    border-radius: 100px;
}

select {
    display: block;
}

.input-user {
    margin: 0 0 0 5px!important;
    border: 0!important;
    box-shadow: none !important;
    padding-left: 1em!important;
}

/*nutricion*/
.nut-title {
    font-size: 13px!important;
}
.nut-value {
    font-size: 12px!important;
    color: #48bb78!important;
}

.nut-title-res {
    font-size: 13px!important;
    color: #48bb78!important;
}
.nut-value-res {
    font-size: 12px!important;
    padding: 0px!important;
    border-color: #48bb78!important;
    box-shadow: none !important;
    padding-left: 0em!important;
}
.table-res {
    border-collapse: collapse;
    border: none;
}

.table-scroll {
    overflow: scroll;
    overflow-y: hidden;
}


.table-scroll::-webkit-scrollbar{
    width: 8px;
    height: 8px;
    background-color: #F5F5F5;
}

.table-scroll::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: #36c21a;
    
}

.nut-table-ing{
    padding: 0 10px;
}
.cul-table-ing{
    padding: 0!important;
}

.activeclass {
    box-shadow: 0px 4px 10px -1px #A6D66B !important;
}



.nut-cult{
    width: 100%!important;
}

.nutcul-title-res {
    font-size: 16px!important;
    color: #48bb78!important;
}
.nutcul-value-res {
    font-size: 15px!important;
    padding: 0px!important;
    border: 0!important;
    box-shadow: none !important;
    padding-left: 0em!important;
}

/*plagas y enfermedades*/
.pl-title {
    font-size: 13px!important;
    color: #48bb78!important;
}
.pl-value {
    font-size: 12px!important;
    color: black!important;
}

.pl-title-res {
    font-size: 12px!important;
    color: #fff!important;
}
.pl-title-res-form {
    font-size: 12px!important;
    color: #48bb78!important;
}
.pl-value-res {
    font-size: 12px!important;
    padding: 0px!important;
    border: 0!important;
    box-shadow: none !important;
    padding-left: 0em!important;
}
.pl-value-res-form {
    font-size: 14px!important;
    padding: 0px!important;
    border-color: #48bb78!important;
    box-shadow: none !important;
    padding-left: 0em!important;
}
.table-res {
    border-collapse: collapse;
}
.table-res-val {
    border-collapse: collapse;
}
th.divide-table {
    background-color: #fff;
    border: none;
}
table, th, td {
    border: inherit!important;
}



.search{
    border-radius: 32px!important;
    padding-left: 10px!important;
}
.in-search {
    margin: 0!important;
    border: none!important;
}


.custom-input-file-pl {
    color: #48bb78;
    cursor: pointer;
    font-size: 14px;
    margin: 20px auto 20px;
    height: 150px;
    border-radius: 100px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    width: 150px;
    border-style: dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-color: #48bb78;
    border: dashed;
}
.custom-input-file-pl .file-input {
    cursor: pointer;
    font-size: 10000px;
    margin: 0;
    opacity: 0;
    outline: 0 none;
    padding: 0;
    position: absolute;
    right: -1000px;
    top: -1000px;
}
.icon-img-pl {
    position: absolute;
    top: 120px;
    left: 160px;
    cursor: pointer;
}


.custom-input-pl {
    margin: 10px;
    position: relative;
    width: 250px;
    display: flex;
    align-items: center;
}
.detail-img-pl {
    width: 200px;
    height: 200px;
}

.ReactModal__Content.ReactModal__Content--after-open {
    height: fit-content;
}

/*ordenes*/
.bt-order-1{
background-color: #A6D66B;
color: white;
}

.bt-order-2{
background-color: #D9F5CD;
color: #A6D66B;
}

.ord-value{
border-bottom: 1px solid #000!important;
}

/*reporte*/
.repo-productor{
    background-color: #CAEAA1;
}
.rp-select {
    background-color: #A6D66B;
    color: #fff;
    cursor: pointer;
    border: 1px solid #fff;
}

.activetab {
    color: #000;
    background-color: #fff!important;
    border-width: 2px 2px 0 2px;
    border-style: solid;
    border-radius: 16px 16px 0 0;
    border-color: #A6D66B;
    padding-bottom: 23px;
}
.content-tab {
    border: 2px solid #A6D66B;
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.content-rp {
    align-content: space-between;
    display: grid;
}
.rp-content {
    border-bottom: 1px;
    border-style: solid;
}
.tr-rp{
    border-bottom: 1px;
    border-style: solid;
    border-color: #A6D66B;
    border-collapse: collapse;
}

.total-ind {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    background: #A6D66B;
    padding: 10px;
    border-radius: 16px;
    top: -50px;
}

table.border-detail {
    border: none!important;
}