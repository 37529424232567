.repo-agrocostos{
    background-color: #CAEAA1;
}

.input-agro {
    border-color: #CAEAA1!important;
    box-shadow: none !important;
    padding-left: 0!important;
}

.select-agro {
    border: 0;
    border-bottom: 1px solid;
    border-radius: inherit;
}

.totales-ind {
    display: flex;
    position: relative;
    padding: 10px;
    border-radius: 16px;
    top: -50px;
}
.bg-totales-ind{
    background: #f1de98;
}

.tr-rp {
    border-bottom: 0px;
}

.bg-grafic{
    background-color: #A6D66B;
}