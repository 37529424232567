header {
    height: 4rem;
}
.bg-altaproductor{
    background: url("../assets/img/partials/header-prod.svg");
    background-repeat: no-repeat;
    background-size:contain;
    height: 100%;
    background-size:100% 100%;
}

.drop{
    cursor: pointer;
}

.subdrop{
    background-color: #badc91;
}

.bg-qr{
    background: url("../assets/img/fondo.png") center center no-repeat;
    background-size: cover;
    height: calc(100vh - 0rem);
}