.img-perfil {
    width: 150px;
    height: 150px;
    border-radius: 100px;
    display: initial;
}

.text-primary-perfil{
    color: #044C3B;
    font-size: 1.5em;
}

.text-primary-reciente{
    color: #A6D66B;
    font-size: 1.5em;
}

.text-secundary-reciente{
    color: #A6D66B;
    font-size: 1em;
}

.text-secundary-n-user{
    color: #707070;
    font-size: 1em;
}

.text-primary-new-user{
    align-self: center;
}

