.text-green{
    color: #A6D66B;    
}
.bg-green{
    background-color: #A6D66B;
}

.border-green{
    border-color: #A6D66B ;
}

.bg-green-ho:hover{
    background-color: #A6D66B;
}

.filemap{
    opacity: 1!important;
}

.custom-input-file-ran {
    color: #48bb78;
    cursor: none;
    font-size: 14px;
    margin: 20px auto 20px;
    height: 215px;
    border-radius: 50px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    width: 215px;
    border-style: dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-color: #48bb78;
    border: dashed;
}
.custom-input-file-ran-2 {
    color: #48bb78;
    cursor: none;
    font-size: 14px;
    margin: 20px auto 20px;
    height: 215px;
    border-radius: 50px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    width: 215px;
    border-style: dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-color: #48bb78;
    border: dashed;
}
.custom-input-file-ran .file-input {
    cursor: pointer;
    font-size: 10000px;
    margin: 0;
    opacity: 0;
    outline: 0 none;
    padding: 0;
    position: absolute;
    right: -1000px;
    top: -1000px;
}
.custom-input-file-ran-2 .file-input {
    /* cursor: pointer; */
    /* font-size: 10000px; */
    margin: 0;
    opacity: 0;
    /* outline: 0 none; */
    padding: 0;
    position: absolute;
    right: -1000px;
    top: -1000px;
}
.icon-img-ran {
    position: absolute;
    top: 190px;
    left: 300px;
    cursor: pointer;
}
.icon-img-ran-map {
    position: absolute;
    top: 160px;
    left: 300px;
    cursor: pointer;
}

.pulsos {
    background-color: #D9F5CD;
    padding: 0em 4em;
    border-radius: 100px;
}

.plant-pul {
    margin-top: -25px;
    margin-bottom: 25px;
    margin-right: 25px;
}

.substep1 {
    /*padding: 100px 0 75px 75px;*/
     margin-top: -75px; 
     background-color: #D9F5CD;
     z-index: 30;
     position: relative;     
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
}
.substep2 {
    background-color: #CAEAA1;
    margin-top: -40px;
    z-index: 20;
    position: relative;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
}
.substep3 {
    background-color: #FAF2BB;
    margin-top: -40px;
    z-index: 10;
    position: relative;

}
.cards-med{
    margin-left: 0!important;
}

.font-tabs{
    margin-bottom: 0!important;
    position: absolute;
    z-index: 0;
    background-color: #D9F5CD;
    width: 100%;
}
.button-send {
    position: absolute;
    z-index: 50;
    bottom: 0;
    right: 0;
}

.custom-input-file-anafis {
    color: #044C3B;
    cursor: none;
    font-size: 40px;
    margin: 20px auto 20px;
    height: 100px;
    border-radius: 25px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    width: 100px;
    border-style: dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-color: #044C3B;
    border: dashed;
    border-width: 2px;
}
.custom-input-file-anafis-2 {
    cursor: none;
    font-size: 14px;
    margin: 20px auto 20px;
    height: 100px;
    border-radius: 25px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    width: 100px;
    border-style: dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-color: white;
    border: none;
    border-width: 2px;
    background-color: white;
}
.custom-input-file-anafis .file-input {
    cursor: pointer;
    font-size: 10000px;
    margin: 0;
    opacity: 0;
    outline: 0 none;
    padding: 0;
    position: absolute;
    right: -1000px;
    top: -1000px;
}
.custom-input-file-anafis-2 .file-input {
    cursor: pointer; 
    font-size: 10000px;
    margin: 0;
    opacity: 0;
    outline: 0 none;
    padding: 0;
    position: absolute;
    right: -1000px;
    top: -1000px;
}

img.iconpdf {
    max-width: 50px!important;
    height: auto!important;
}

.bg-green-color{
    background-color: #A6D66B;
}

.bg-green-color-hover:hover{
    background-color: #A6D66B;
    color: white ;
}

.green-color{
    color: #A6D66B;
}

.border-green-color{
    border-color: #A6D66B;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: #5da743!important;
}

.mayus{
    text-transform:uppercase;
}

.stepend{
    margin-top: -6rem;
    background-color: #D9F5CD;
    z-index: 30;
    position: relative;
}