@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;700;800;900&display=swap');


.font-body {
  font-family: 'Montserrat', sans-serif;
}

.bg-primary {
  background-color: #044C3B;
}

.bg-secondary {
  background-color: #96D360;
}

.bg-third {
  background-color: #F4D35B;
}

.bg-active {
  background-color: #84A21D;
}

.bg-four {
  background-color: #D9F5CD;
}

.bg-five {
  background-color: #A6D66B;
}

.bg-six {
  background-color: #CAEAA1;
}

.bg-seven {
  background-color: #FAF2BB;
}



.bg-active-menu {
  background-color: #84A21D;
}

.text-primary {
  color: #044C3B;
}

.text-secondary {
  color: #96D360;
}

.text-third {
  color: #F4D35B;
}

.text-active {
  color: #84A21D;
}

.text-four {
  color: #D9F5CD;
}



.text-five {
  color: #A6D66B;
}

.text-six {
  color: #FFFFFF;
}

.ingenieros {
  background: url("assets/img/welcome.png") center center no-repeat;
  background-size: cover;
  height: calc(100vh - 4rem);
}

.ingenieros .item {
  padding: 0.5rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 400px;
}

.ingenieros .item img {
  max-width: 80%;
}

.admin {
  width: 100%;
  min-width: 100vw;
}

.logo {
  @apply w-20;
}
.alta-layout{
  background-color: #FFFFFF;
}
/*..................MODULE ALTA PRODUCTOR*/

.tx-1{
  position: absolute;
  left: 332px;  
  bottom: 230px;
  color: #FFFFFF; 
}
.tx-2{
  position: absolute;
  left: 332px;  
  bottom: 115px;
  color: #FFFFFF;
}
.tx-3{
  position: absolute;
  left: 332px;  
  bottom: 75px;
  color: #FFFFFF;
}
.img-qr{
  position: absolute;
  left: 332px;  
  bottom: -50px;
  color: #FFFFFF;
}  

/*forms*/
.input-1{
  width: 100% !important;
  height: 3rem !important;
  border-bottom: 1px solid #A6D66B !important;
}
.input-2{
width: 95% !important;
height: 3rem !important;
border-bottom: 1px solid #A6D66B !important;
}

.input-3{
  width: 70% !important;
  height: 3rem !important;
  border-bottom: 1px solid #A6D66B !important;
}
.input-4{
  width: 80% !important;
  height: 3rem !important;
  border-bottom: 1px solid #A6D66B !important;
 }
 .input-5{
  width: 60% !important;
  height: 3rem !important;
  border-bottom: 1px solid #A6D66B !important;
 }
 .input-6{
  width: 90% !important;
  height: 3rem !important;
  border-bottom: 1px solid #A6D66B !important;
 }  

 .input-7{
  width: 100% !important;
  height: 3rem !important;
  border-bottom: 1px solid #A6D66B !important;
}

.input-8{
  width: 50% !important;
  height: 3rem !important;
  border-bottom: 1px solid #A6D66B !important;
}

/*input bg*/
 .input-field input[type=text]:focus + label {
  color: #A6D66B !important;
}
.input-field input[type=text]:focus {
  border-bottom: 1px solid #A6D66B !important;
  box-shadow: 0 1px 0 0 #A6D66B!important;
}
.input-field>label {
  color: #A6D66B !important;
}


.input-field input[type=date]:invalid::-webkit-datetime-edit {
  color: #999!important;
}

/*input sm*/
.input-field-2 input[type=text]:focus + label {
  color: #A6D66B !important;
}
.input-field-2 input[type=text]:focus {
  border-bottom: 1px solid #A6D66B !important;
  box-shadow: 0 1px 0 0 #A6D66B!important;
}
.input-field-2>label {
  color: #A6D66B !important;

}
.input-field-2 input[type=date]:invalid::-webkit-datetime-edit {
  color: #999!important;
}
/*******************************************************/


.next-1{
  float:right;
  width: auto;
  height: 150px;
  }
/*font size*/
.fl{
  float: left;
}
.title-1{
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
}
.title-2{
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 30px;
}
.title-3{
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 20px;
}
.title-4{
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 26px;
}
.title-5{
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
}

.title-6{
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 24px;
  text-align: center;
}
/*boxes*/
.box-1{
  width: 30%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.qr-example{
  max-width: 100%;
  height: auto;
  width: 155px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.input-new{
  width: 100% !important;
  height: 2rem !important;
  border-bottom: 1px solid #FFFFFF !important;
}
.input-new:hover{
  width: 100% !important;
  height: 2rem !important;
  border-bottom: 1px solid #FFFFFF !important;
}
.input-new:focus{
  width: 100% !important;
  height: 2rem !important;
  border-bottom: 1px solid #FFFFFF !important;
}

.input-new::placeholder{
  color: #FFFFFF!important;
  text-align: center;
}
.form-layout{
  margin-top:100px;
  margin-bottom:20px;
}
.box-space{
  margin-top: 10px;
  margin-bottom: 5px;
}
.box-space-sm{
  margin-top: 10px;
  margin-bottom: 5px;
}
/*medicion*/
.med-1{
  position: absolute;
  left: 194px;
  bottom: 430px;
  color: #ffffff;
  font-size: 14px;
} 
.med-2{
  position: absolute;
  left: 300px;
  bottom: 430px;
  color: #ffffff;
  font-size: 14px;
}  
.med-3{
  position: absolute;
  left: 415px;
  bottom: 430px;
  color: #ffffff;
  font-size: 14px;
} 
.med-4{
  position: absolute;
  left: 522px;
  bottom: 430px;
  color: #ffffff;
  font-size: 14px;
} 
.med-5{
  position: absolute;
  left: 625px;
  bottom: 430px;
  color: #ffffff;
  font-size: 14px;
} 
.med-6{
  position: absolute;
  left: 735px;
  bottom: 430px;
  color: #ffffff;
  font-size: 14px;
} 
.med-7{
  position: absolute;
  left: 840px;
  bottom: 430px;
  color: #ffffff;
  font-size: 14px;
} 
.med-8{
  position: absolute;
  left: 950px;
  bottom: 430px;
  color: #ffffff;
  font-size: 14px;
} 
.med-9{
  position: absolute;
  left: 1060px;
  bottom: 430px;
  color: #ffffff;
  font-size: 14px;
} 
.med-10{
  position: absolute;
  left: 1165px;
  bottom: 430px;
  color: #ffffff;
  font-size: 14px;
} 
.med-11{
  position: absolute;
  left: 1290px;
  bottom: 430px;
  color: #ffffff;
  font-size: 14px;
} 


/* button rounded*/
button {
  cursor: pointer;
  outline: none;
}
button.outline {
  position: relative;
  width: 95%;
  z-index: 3;
  background: transparent;
  color: #A6D66B; 
  font-size: 12px;
  border-color: #A6D66B;
  border-style: solid;
  border-width: 2px;
  border-radius: 22px;
  padding: 10px 40px;
  text-transform: uppercase;
  transition: all 0.2s linear;
}
button.outline a {
  text-decoration: none;
}
button.outline:hover {  
  color: white;
  background: #A6D66B;
  border-color: white;
  transition: all 0.2s linear;
}
button.outline:active {
  border-radius: 22px;
}

/* button rounded*/
button {
  cursor: pointer;
  outline: none;
}
button.outline-yellow {
  position: relative;
  width: 55%;
  z-index: 3;
  background: transparent;
  color: #ffffff; 
  font-size: 12px;
  border-color: #F4D35B;
  border-style: solid;
  border-width: 2px;
  border-radius: 22px;
  padding: 10px 40px;
  text-transform: uppercase;
  transition: all 0.2s linear;
}
button.outline-yellow a {
  text-decoration: none;
}
button.outline-yellow:hover {  
  color: white;
  background: #F4D35B;
  border-color: #F4D35B;
  transition: all 0.2s linear;
}
button.outline-yellow:active {
  border-radius: 22px;
}


/* button circle*/

button.outline-2 {
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 3;
  background: transparent;
  color: #A6D66B;
  font-size: 12px;
  border-color: #A6D66B;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  padding: 5px 5px;
  text-transform: uppercase;
  transition: all 0.2s linear;
}
button.outline-2 a {
  text-decoration: none;
}
button.outline-2:hover {  
  color: white;
  background: #A6D66B;
  border-color: white;
  transition: all 0.2s linear;
}
button.outline-2:active {
  border-radius: 22px;
} 



/*select rounded*/
 select {
  background-color: rgba(255,255,255,0.9);
  width: 75%;
  padding: 10px;
  border: 1px solid #A6D66B;  
  border-radius: 27px;
  height: 3rem;
}

/*button rounded height*/
button {
  cursor: pointer;
  outline: none;
}
button.big-rounded {
  position: relative;
  width: 20%;
  height: 150px;
  z-index: 3;
  background: transparent;
  color: #A6D66B; 
  font-size: 12px;
  border-color: #A6D66B;
  border-style: solid;
  border-width: 2px;
  border-radius: 22px;
  /*padding: 10px 40px;*/
  text-transform: uppercase;
  transition: all 0.2s linear;
  margin-right: 35px ;
}
button.big-rounded  a {
  text-decoration: none;
}
button.big-rounded:hover {  
  color: white;
  background: #A6D66B;
  border-color: white;
  transition: all 0.2s linear;
}
.activebr {
  color: white!important;
  background: #A6D66B!important;
  border-color: white!important;
}
   


/*media queries*/

@media screen and (max-width: 1280px) {

  /*text alta productor*/
 .tx-1{
 position: absolute;
  left: 262px;
  bottom: 140px;
  color: #FFFFFF;  
 }
 .tx-2{
  position: absolute;
  left: 262px;
  bottom: 25px;
  color: #FFFFFF; 
 }
 .tx-3{
  position: absolute;
  left: 262px;
  bottom: -16px;
  color: #FFFFFF; 
 }
 .img-qr{
  position: absolute;
  left: 262px;
  bottom: -185px;
  color: #FFFFFF; 
 }
 .next-1{
  float:right;
  width: auto;
  height: 95px;
  }
 
}

@media screen and (max-width: 992px) {

  /*text alta productor*/
  .tx-1{
    position: absolute;
    left: 206px;
    bottom: 112px;
    color: #FFFFFF;

  }
  .tx-2{
    position: absolute;
    left: 206px;
    bottom: -2px;
    color: #FFFFFF;
  }
  .tx-3{
    position: absolute;
    left: 206px;
    bottom: -43px;
    color: #FFFFFF;
  }
  .img-qr{
    position: absolute;
    left: 206px;
    bottom: -200px;
    color: #FFFFFF;
  }
  .next-1{
    float:right;
    width: auto;
    height: 95px;
    }
  
}


@media screen and (max-width: 600px) {

   /*text alta productor*/
  .tx-1{
    position: absolute;
    left: 255px;
    bottom: 143px;
    color: #FFFFFF;   
  }
  .tx-2{
    position: absolute;
    left: 255px;
    bottom: 30px;
    color: #FFFFFF; 
  }
  .tx-3{
    position: absolute;
    left: 255px;
    bottom: -12px;
    color: #FFFFFF;
  }
  .img-qr{
    position: absolute;
    left: 255px;
    bottom: -188px;
    color: #FFFFFF;   
  }
  .next-1{
    float:right;
    width: auto;
    height: 95px;
    }
  
}

@media screen and (max-width: 400px) {

  /*text alta productor*/
 .tx-1{
  position: absolute;
  left: 142px;
  bottom: 108px;
  color: #FFFFFF; 
 }
 .tx-2{
    position: absolute;
    left: 145px;
    bottom: -6px;
    color: #FFFFFF;
 }
 .tx-3{
  position: absolute;
  left: 140px;
  bottom: -46px;
  color: #FFFFFF;
 }
 .img-qr{
  position: absolute;
  left: 145px;
  bottom: -235px;
  color: #FFFFFF; 
 }

 .next-1{
  float:right;
  width: auto;
  height: 95px;
  }
 
}

/*range input*/
input[type=range]::-webkit-slider-thumb {
  background-color: #5C8ACA;
}
input[type=range]::-moz-range-thumb {
  background-color: #5C8ACA;
}
input[type=range]::-ms-thumb {
  background-color: #5C8ACA;
}

input[type=range] + .thumb {
  background-color: #dedede;
}
input[type=range] + .thumb.active .value {
  color: #5C8ACA;
}
/*icons range*/
.icon-goteros{
  width: 100%;
}
.icon-line{
  width: 100%;
}
/*cards*/
.card-white{
  max-width: 100%;
  width: 600px;
  height: 350px;
  background-color: #FFFFFF;
  border-radius: 30px;
}
.card-white2{
  max-width: 100%;
  width: 700px;
  height: 300px;
  background-color: #FFFFFF;
  border-radius: 30px;
}
.card-white3{
  max-width: 50%;
  width: 600px;
  height: 300px;
  background-color: #FFFFFF;
  border-radius: 30px;
}
/*tabs*/
.tab1{
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.tab2{
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.tab3{
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}



.prev {
  display: none;
  width: 95px;
  height: 92px;
  margin: 2px;
  border-radius: 15px;
}
/*box form*/
.box-form{
  padding: 15px 15px 15px 15px;
}
/*BOXES SPACE*/
.box-space{
  margin-top: 10px;
  margin-bottom: 5px;
}
.box-space-sm{
  margin-top: 10px;
  margin-bottom: 5px;
}
.box-space-tiny{
  margin-top: 5px;
  margin-bottom: 20px;
}

.box-space-105{
  margin-top: 100px;
  margin-bottom: 5px;
}
.box-space-35{
  margin-top: 30px;
  margin-bottom: 5px;
}
.box-space-25{
  margin-top: 20px;
  margin-bottom: 5px;
}
.box-padding-15{
  margin-top: 5px;
    margin-bottom: 3px;
    padding: 30px 26px 5px 125px;
}
.box-space-5{
  margin-top: 22px;
  margin-bottom: 7px;
  margin-left: 25px;
}
.box-padding-10{
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px;
}
.box-padding-20{
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 20px;
}
.box-padding-30{
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 30px;
}
.box-padding-50{
  margin-top: 180px;
  margin-bottom: 10px;
}
/*BOXES*/
.box-green{
  max-width: 100%;
  height: auto;
  width: 300px;
  padding: 45px 40px 45px 45px;
}

/*IMG*/
.human1{
  max-width: 100%;
  width: 200px;
  height: 200px;
}
.plant1{
  position: absolute;
  max-width: 100%;
  width: 250px;
  height: 500px;
}
/*TEXT FONTS*/
.title-14{
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  text-align: center;
  align-items: center;
}
.title-16{
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  text-align: center;
  align-items: center;
}
.title-18{
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
  text-align: center;
  align-items: center;
}
.title-18n{
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 18px;
}
/*LINES*/
hr.line-white{
  border: 1px solid #FFFFFF;
  align-items: center;
}
/*BUTTONS*/
/*materialize css change*/
.btn-large i {
  font-size: 2.5rem;
  background-color: #F4D35B !important;
  color: #FFFFFF;
}
.btn-floating {
  background-color: #F4D35B !important;
}
/*CARDS*/
.card-productor{
  max-height: 100%;
  height: 660px;
  background-color: #A6D66B;
  border-top-left-radius: 150px;
  border-top-right-radius: 150px;
  border-bottom-left-radius: 150px;
  border-bottom-right-radius: 150px;
}
.card-medicion{
  width: 300px;
  max-height: 100%;
  height: 600px;
  margin-top: 300px;
  background-color: #A6D66B;
  border-top-left-radius: 150px;
  border-top-right-radius: 150px;
  border-bottom-left-radius: 150px;
  border-bottom-right-radius: 150px;
}




/*modulo parametro medicion*/
.collapsible-header, .collapsible-body, .collapsible, ul.collapsible>li 
{
  margin: 0!important;;
  padding: 0!important;
  border: 0!important;
  box-shadow: none!important;
}
/*upload file*/
.label {
  width: 100px;
  border: 2px dashed grey;
  border-radius: 30px;
  display: block;
  height: 100px;
  padding: 1.2em;
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
}
.label i {
  display: block;
  font-size: 42px;
  padding-bottom: 16px;
}
.label i,
.label .title {
  color: grey;
  transition: 200ms color;
}
.label:hover {
  border: 2px solid black;
}
.label:hover i,
.label:hover .title {
  color: black;
}

input[type=file] {
  outline: 0;
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

/* input file style */
.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.input-file + .js-labelFile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  cursor: pointer;
}
.input-file + .js-labelFile .icon:before {
  content: "";
}
.input-file + .js-labelFile.has-file .icon:before {
  content: "";
  color: #5AAC7B;
}

/*padding margin extra*/
.pad50{
  padding: 50px;
}
.pad40{
  padding: 40px;
}
.pad30{
  padding: 30px;
}
.pad20{
  padding: 20px;
}
.pad10{
  padding: 10px;
}
.pad5{
  padding: 5px;
}
.mleft30{
  margin-left: 30px;
}
.mtop15{
  margin-top: 15px;
}
.font18{
  font-size: 18px;
}
.superponer{
  float: none;
  content: '';
  position: relative;
  z-index: -2;
  box-sizing: content-box;
}
.fr{
  float: right;
}
.space-qr0{
  height: 100px;
}
.space-qr{
  height: 150px;
}
.space-qr2{
  height: 250px;
}

.helper-text{
  font-size: 11px;
  color: red;
  position: relative;
  min-height: 18px;
  display: block;
}

.input-field .helper-text {
  position: relative;
  min-height: 18px;
  display: block;
  font-size: 11px;
  color: red;
}

/*PROGRESS BAR*/

.card-progress{
  height: calc(100vh - 4rem);
  width: 65px;
  box-shadow: 6px 0 10px -10px rgba(162, 162, 162, 0.8);
  background-color: #FFFFFF; 
  float: left;
  z-index: 1000;
  position: fixed;
  top: 5.35rem;
}

.menu-progress{
  padding: 35px 8px 35px 15px;
  margin: 10px;
  
}

button.outline-progress{
  display: block;
  position: relative;
  width: 55px;
  height: 55px;
  z-index: 3;
  background: white;
  color: #A6D66B;
  font-size: 12px;
  border-style: solid;
  border-radius: 50%;
  padding: 2px 2px 2px 2px;
}
button.outline-progress:focus{
  display: block;
  position: relative;
  width: 55px;
  height: 55px;
  z-index: 3;
  background: #A6D66B;
  color: #ffffff;
  font-size: 12px;
  border-style: solid;
  border-radius: 50%;
  padding: 2px 2px 2px 2px;
}


.outline-progress span.user {
  background: url(assets/img/partials/usergreen.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  width: 35px;
}

.outline-progress span.gps-green {
  background: url(assets/img/icons/gps-green.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  width: 35px;
}

.outline-progress span.gps {
  background: url(assets/img/icons/gps.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  width: 35px;
}

.outline-progress span.plant-green {
  background: url(assets/img/icons/plant-green.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  width: 35px;
}

.outline-progress span.plant {
  background: url(assets/img/icons/plant.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  width: 35px;
}

.outline-progress span.water-green {
  background: url(assets/img/icons/water-green.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  width: 35px;
}

.outline-progress span.water {
  background: url(assets/img/icons/water.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  width: 35px;
}

.outline-progress span.botin-green {
  background: url(assets/img/icons/botin-green.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  width: 35px;
}
.outline-progress span.botin {
  background: url(assets/img/icons/botin.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  width: 35px;
}
.outline-progress span.ensayo {
  background: url(assets/img/icons/ensayo-green.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  width: 35px;
}

.outline-progress span.lupa {
  background: url(assets/img/icons/lupa-green.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  width: 35px;
}
.outline-progress span.left-arrow {
  background: url(assets/img/icons/left-arrow-green.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  width: 35px;
}
.outline-progress span.logout{
  background: url(assets/img/partials/logout.svg) no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 42px;
  width: 35px;
}






/*LOGO*/
.logo-100{
   max-width:100%;
   width: 200px;
   height: 100px;
}


/* fixes */

.box-1 {
  width: 50%;
}

.title-2 {
  font-size: 1.25rem;
}

.form-layout {
  margin-top: 6rem;
}

.box-space {
  margin: 0;
}

.title-3 {
  font-size: 1.125rem;
  margin: 0;
}

.input-field {
  margin-bottom: 0;
  margin-top: 0.75rem;
}

.row.pad10 {
  margin: 0;
}


.outline-progress span,  .outline-progress img{
  height: 2rem !important;
  margin: 0 auto;
  width: 2rem !important;
  background-position: center !important;
  margin-top: 0.25rem;
}

.outline-progress.active {
  background: #A6D66B;
  margin-left: 0.4rem;
  border: 2px solid white;
  box-shadow: -0.1rem -0.1rem 0.2rem rgba(0,0,0,0.2);
}

.menu-progress li {
  width: 3.667rem;
}

.navb {
  border-bottom-right-radius: 6rem;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.dashboard {
  padding-top: 6rem;
}

input:not([type]), input[type=text]:not(.browser-default), input[type=password]:not(.browser-default), input[type=email]:not(.browser-default), input[type=url]:not(.browser-default), input[type=time]:not(.browser-default), input[type=date]:not(.browser-default), input[type=datetime]:not(.browser-default), input[type=datetime-local]:not(.browser-default), input[type=tel]:not(.browser-default), input[type=number]:not(.browser-default), input[type=search]:not(.browser-default), textarea.materialize-textarea {
  font-size: 0.9rem !important;
  height: 2rem !important;
  line-height: 2rem !important;
}

.input-field>label {
  font-size: 0.9rem !important;
}

.card-productor .input-field>label, .card-productor .input-field>label.active.white-text {
  color: #fff !important;
}


/* nav */

.nav-toogle {
  @apply transform w-64 bg-five flex flex-col fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30;
}

.nav-toogle + header {
  width: calc(100% - 16rem);
  @apply ml-64 transition-all duration-300;
}

.nav-toogle span {
  @apply ml-2;
}


.nav-toogle.small {
  @apply w-16 overflow-x-hidden;
}

.nav-toogle.small span {
  @apply transition-all duration-300 hidden;
}

.nav-toogle.small + header {
  width: calc(100% - 4rem);
  @apply ml-16 transition-all duration-300;
}

.menu-item {
  @apply flex text-left text-white p-2 items-center;
}

.nav-toogle.small .menu-item {
  justify-content: center;
}

 /* container */
.content-main {
  width: calc(100% - 4rem);
  @apply ml-16 transition-all duration-300;
}

.content-main.uncollapse {
  width: calc(100% - 16rem);
  @apply ml-64 transition-all duration-300;
}

